import React, { useContext } from 'react';
// import styled from 'styled-components';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { formatDate, __, getClaimReference } from '@adac/core-model';
import {
  SubPage,
  Title,
  LightSubTitle,
  // TextField,
  // Button,
  // SubTitle,
  // ColumnLayout as InputColumnLayout,
  Button,
  useLocation,
  CaseDocuments,
  WarningMessage,
  Text,
  AcceptConditionsComponent,
  getTacCheckboxItems,
} from '@adac/core-view';

import StoreContext from '../../stores';
import { useStatusListeners } from '../../hooks/useStatusListeners';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';


const FormLayout = styled.div`
  &>* {
    margin-bottom: 18px;
  }
`;

export default observer(() => {
  const { case: caseStore } = useContext(StoreContext);
  const { search } = useLocation();
  // TODO: more secure soliution to prevent others (snd admin or driver to submit survey results
  const showForm = !search && !search.match(/hideSurvey/);

  useStatusListeners(caseStore);


  const title =`${__('Hermes Id')}. ${getClaimReference(caseStore.commissioningReference)} · ${__('Datum')} ${formatDate(new Date())}`;

  const isInsurance = useIsInsuranceProduct();

  return (
    <>
      <SubPage>
        <Title>{__('Your documents')}</Title>
        {!isInsurance && <LightSubTitle>{title}</LightSubTitle>}

        <CaseDocuments
          token={caseStore.token}
          commissionReport={caseStore.commissionReport}
          commissionExtraDamage={caseStore.commissionExtraDamage}
          finalStatusReport={caseStore.finalStatusReport?.customer}
        />
        <AcceptConditionsComponent
          items={getTacCheckboxItems(caseStore?.productType, {
            acceptTAC: !!caseStore?.acceptTAC,
            acceptWithdrawal: !!caseStore?.acceptWithdrawal,
            acceptPrivacyPolicy: !!caseStore?.acceptPrivacyPolicy,
            confirmAuthorization: !!caseStore?.confirmAuthorization,
            acceptIsAdult: !!caseStore.acceptIsAdult,
            // isTermsAndConditionsAccepted: !!caseStore?.acceptTAC,
            // isWithdrawalPolicyAccepted: !!caseStore?.acceptWithdrawal,
            // isPrivacyPolicyAccepted: !!caseStore?.acceptPrivacyPolicy,
            // isConfirmedAuthorization: !!caseStore?.confirmAuthorization,
            // isAdult: !!caseStore.acceptIsAdult,
          })}
          readOnly
        />
        {!isInsurance && (
          <WarningMessage marginTop="24px">
            <Text>
              {__('info.invoicing.text.customer')}
              {' '}
              <a href={`mailto:${__('info.invoicing.email')}?subject=${title}`}>{__('info.invoicing.email')}</a>
            </Text>
          </WarningMessage>
        )}
      </SubPage>
      {showForm && (
        <SubPage style={{ margin: '40px 0px' }}>
          <FormLayout>
            {/* <SubTitle big>Dokumente per E-Mail erhalten</SubTitle>
            <InputColumnLayout ratio="7fr 3fr">
              <TextField
                name="email"
                type="email"
                readonly
                badgeTitle="E-Mail"
                badgeEqualsPlaceholder
                defaultValue="max@muster.de"
              />
              <Button cta title={__('Send')} />
            </InputColumnLayout> */}
            {!caseStore.feedback && (
              <Button
                cta
                title={__('Feedback survey')}
                link="/survey"
                // disabled={caseStore.sendingSurvey}
              />
            )}
          </FormLayout>
        </SubPage>
      )}
    </>
  );
});
