import {
  LogoMobile,
  LogoOpenhouse, FlexView,
  LogoOpenhouseComponent,
  LogoMobileComponent,
} from '@adac/core-view';


import React from 'react';
import WorkaroundStatusChangerCointainer from '../dev/WorkaroundStatusChangerCointainer';
import PhoneIcon from './NavBarContainer';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';
import { observer } from 'mobx-react';

const AdacLogo = observer(() => {
  const isInsurance = useIsInsuranceProduct();
  console.log('isInsurance', isInsurance);
  if (isInsurance) return <LogoMobileComponent />
  return <LogoMobile href="https://www.adac.de/produkte/schluesselnotdienst/" />;
});

const SndCustomerLogo = observer(() => {
  const isInsurance = useIsInsuranceProduct();
  if (isInsurance) return <LogoOpenhouseComponent />
  return <LogoOpenhouse href="https://www.adac.de/produkte/schluesselnotdienst/" />;
});

const Header = () => (
  <FlexView start center>
    <AdacLogo />
    <SndCustomerLogo />
    <WorkaroundStatusChangerCointainer />
    <PhoneIcon />
  </FlexView>
);

export default Header;
