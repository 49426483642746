import React, { useContext } from 'react';
import {
  Security, RouterContextContainer,
} from '@adac/core-view';
import { Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { LayoutContainer } from './components/layout';
import DevelopmentHelperPages from './components/pages/DeveloperHelperPages';
import StoresContext from './stores';


const App = () => {
  const stores = useContext(StoresContext);

  async function setCaseToken(urlToken: string) {
    const { token } = await stores.case.fetchByToken(urlToken);
    if (urlToken !== token && token) {
      console.log('TODO: smarter redirect than location.href=/', token, 'from ', window.location.pathname);
      window.location.href = `/${token}${window.location.search}`;
    }
  }

  return (
    <Security>
      <ThemeProvider theme={stores.ui.currentTheme}>
        <RouterContextContainer
          setCaseToken={setCaseToken}
          routes={[<Route pageTransition="slideUp" path="/dev" component={DevelopmentHelperPages} />]}
        >
          <LayoutContainer />
        </RouterContextContainer>
      </ThemeProvider>
    </Security>
  );
};

export default App;
